import { BusinessData } from "../../../types/BusinessData";
import { NavbarLinkData } from "../../../types/NavbarLinkData";
import { CONSTANT, ROUTES } from "../../constant";
import menuIcon from "../image/OverviewNew.svg";
import PersonalTaxImg from "../../../personalTaxImage";
import personLC from "../image/lcPerson.svg";
// import personsPT from "../image/ptPersons.svg";
import partnershipTaxIcon from "../image/PartnershipTaxIcon.svg";
import limitedCompanyIcon from "../image/LimitedCompanyIcon.svg";
import personalTaxIcon from "../image/PersonalTaxIcon.svg";
import iagIcon from "../image/iag.svg";
import settingsIcon from "../image/settingsIcon.svg";
import taxFile from "../image/taxFile.svg";
import paymentIcon from "../image/credit-card-pay.svg";

/* The `admiNavbarLinkData` constant is an array of objects that represent the navigation links for the
admin section of a website or application. Each object in the array contains a `link` property which
specifies the route to navigate to and a `label` property which represents the text to display for
that link. */
export const admiNavbarLinkData = [
  {
    link: ROUTES.ADMIN_OVERVIEW,
    label: CONSTANT.OVERVIEW,
    icon: PersonalTaxImg,
    props: {
      width: 15,
      height: 30,
      stroke: "#000000",
      strokeWidth: 1,
      iconName: "AdminOverview",
    },
    hoverProps: {
      width: 40,
      height: 25,
      stroke: "#000000",
      strokeWidth: 1,
    },
  },
  {
    link: ROUTES.ADMIN_BUSINESSES,
    label: CONSTANT.BUSINESSES,
    icon: PersonalTaxImg,
    props: {
      width: 30,
      height: 20,
      stroke: "#000000",
      strokeWidth: 4,
      iconName: "AdminBusiness",
    },
    hoverProps: {
      width: 40,
      height: 25,
      stroke: "#000000",
      strokeWidth: 1,
    },
  },
  {
    link: ROUTES.ADMIN_ACCOUNTANTS,
    label: CONSTANT.ACCOUNTANTS,
    icon: PersonalTaxImg,
    props: {
      width: 30,
      height: 20,
      stroke: "#000000",
      strokeWidth: 4,
      iconName: "AdminAccountants",
    },
    hoverProps: {
      width: 40,
      height: 25,
      stroke: "#000000",
      strokeWidth: 1,
    },
  },
  {
    link: ROUTES.ADMIN_CUSTOMERS,
    label: CONSTANT.CUSTOMERS,
    icon: PersonalTaxImg,
    props: {
      width: 30,
      height: 20,
      // stroke: "#000000",
      strokeWidth: 4,
      iconName: "AdminCustomers",
    },
    hoverProps: {
      width: 40,
      height: 25,
      // stroke: "#000000",
      strokeWidth: 1,
    },
  },
  {
    link: ROUTES.ADMIN_SETTINGS,
    label: CONSTANT.SETTINGS,
    icon: PersonalTaxImg,
    props: {
      width: 30,
      height: 20,
      // stroke: "#000000",
      strokeWidth: 4,
      iconName: "AdminSettings",
    },
    hoverProps: {
      width: 40,
      height: 25,
      // stroke: "#000000",
      strokeWidth: 1,
    },
  },
];

/* The `customerNavbarLinkData` constant is an array of objects that represent the navigation links for
the customer section of a website or application. Each object in the array contains a `link`
property which specifies the route to navigate to and a `label` property which represents the text
to display for that link. */
export const customerNavbarLinkData: NavbarLinkData[] = [
  {
    link: ROUTES.OVERVIEW,
    label: CONSTANT.OVERVIEW,
    icon: PersonalTaxImg,
    props: {
      width: 20,
      height: 30,
      stroke: "#000000",
      strokeWidth: 1,
      iconName: "Overview",
    },
    hoverProps: {
      width: 40,
      height: 25,
      stroke: "#000000",
      strokeWidth: 1,
    },
  },
  {
    link: ROUTES.INTERMEDIATE_TAX_PAGE,
    label: CONSTANT.PERSONAL_TAX,
    icon: PersonalTaxImg,
    props: {
      width: 25,
      height: 25,
      stroke: "#000000",
      strokeWidth: 1,
      iconName: "Personal Tax",
    },
    hoverProps: {
      width: 40,
      height: 20,
      stroke: "#000000",
      strokeWidth: 1,
    },
  },
  {
    link: ROUTES.LIMITED_COMPANY,
    label: CONSTANT.LIMITED_COMPANY,
    icon: PersonalTaxImg,
    props: {
      width: 30,
      height: 25,
      stroke: "#000000",
      strokeWidth: 1,
      iconName: "LimitedCompany",
      hoverStroke: "#006aa3",
    },
  },
  {
    link: ROUTES.PARTNERSHIP_TAX,
    label: CONSTANT.PARTNERSHIP_TAX,
    icon: PersonalTaxImg,
    props: {
      width: 30,
      height: 20,
      stroke: "#000000",
      strokeWidth: 1,
      iconName: "PartnershipTax",
    },
  },
];

/* The `accountantNavbarLinkData` constant is an array of objects that represent the navigation links
for the accountant section of a website or application. Each object in the array contains a `link`
property which specifies the route to navigate to and a `label` property which represents the text
to display for that link. */
export const accountantNavbarLinkData = [
  {
    link: ROUTES.ACCOUNTANT_OVERVIEW,
    label: CONSTANT.OVERVIEW,
    icon: PersonalTaxImg,
    props: {
      width: 20,
      height: 30,
      stroke: "#000000",
      strokeWidth: 1,
      iconName: "AccountantOveview",
    },
    hoverProps: {
      width: 40,
      height: 25,
      stroke: "#000000",
      strokeWidth: 1,
    },
  },
  // {
  //   link: ROUTES.ACCOUNTANT_SETTINGS,
  //   label: CONSTANT.SETTINGS,
  //   icon: PersonalTaxImg,
  //   props: {
  //     width: 30,
  //     height: 23,
  //     strokeWidth: 4,
  //     iconName: "AccountantSettings",
  //   },
  //   hoverProps: {
  //     width: 40,
  //     height: 25,
  //     strokeWidth: 1,
  //   },
  // },
];

export const serviceManagerNavbarLinkData = [
  {
    link: ROUTES.SERVICE_MANAGER_OVERVIEW,
    label: CONSTANT.OVERVIEW,
    icon: PersonalTaxImg,
    props: {
      width: 20,
      height: 30,
      stroke: "#000000",
      strokeWidth: 1,
      iconName: "ServiceManagerOverview",
    },
    hoverProps: {
      width: 40,
      height: 25,
      stroke: "#000000",
      strokeWidth: 1,
    },
  },
];
