import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseURL } from "../utility/config/config";
import { StorageConstants } from "../utility/constant";

type PostsResponse = any;

interface AuthStatusResponse {
  status: boolean;
  message: string;
  statusCode: number;
  data: Record<string, unknown>;
}

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Use the original fetchBaseQuery for making the request
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: `${BaseURL}/api/v1/`,
    prepareHeaders: (headers, { getState }: any) => {
      const token = getState()?.loginSlice?.userLoginInfo?.data?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  let result = await rawBaseQuery(args, api, extraOptions);

  if ((result?.error?.data as AuthStatusResponse)?.statusCode === 401) {
    const refreshToken = api.getState().loginSlice?.userLoginInfo?.data?.refreshToken;
    const accessToken = api.getState().loginSlice?.userLoginInfo?.data?.token;

    // Try to get new tokens using the refresh token
    const refreshResult = await fetchBaseQuery({
      baseUrl: `${BaseURL}/api/v1/`,
    })(
      {
        url: "auth/refreshToken",
        method: "POST",
        body: { accessToken, refreshToken },
      },
      api,
      extraOptions,
    );

    if ((refreshResult?.data as { status: any })?.status) {
      api.dispatch({
        type: "loginSlice/updateTokens",
        payload: refreshResult?.data,
      });

      // Retry the original request with the new token
      result = await rawBaseQuery(args, api, extraOptions);
    } else {
      // Refresh token failed, log out the user
      api.dispatch({ type: "loginSlice/logout" });
    }
  }

  return result;
};

export const taxdApi = createApi({
  reducerPath: "taxdApi",
  baseQuery: baseQueryWithReauth, // Use the custom baseQuery with 401 handling
  tagTypes: ["formsData", "configSettings", "sandboxSettings", "profileData"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ patch, role }) => ({
        url: `auth/login?role=${role}`,
        method: "POST",
        body: patch,
      }),
    }),
    register: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/register",
        method: "POST",
        body: patch,
      }),
    }),
    verifyOtp: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/verifyOtp",
        method: "POST",
        body: patch,
      }),
    }),
    resendOtp: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/resendOtp",
        method: "PUT",
        body: patch,
      }),
    }),
    forgotPassword: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/forgetPassword",
        method: "POST",
        body: patch,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/resetPassword",
        method: "POST",
        body: patch,
      }),
    }),
    // to fetch the business reports for admin dashboard
    businessReports: builder.mutation({
      query: ({ patch }) => ({
        url: "admin/businessReports",
        method: "POST",
        body: patch,
      }),
    }),
    // to add new business for admin
    addBusiness: builder.mutation({
      query: ({ patch }) => ({
        url: "admin/business",
        method: "POST",
        body: patch,
      }),
    }),

    // to add new accountant for admin
    addAccountant: builder.mutation({
      query: ({ patch }) => ({
        url: "accountant",
        method: "POST",
        body: patch,
      }),
    }),
    // to fetch the accountant reports for accountant dashboard
    accountantReports: builder.mutation({
      query: ({ patch }) => ({
        url: "accountantProgress",
        method: "POST",
        body: patch,
      }),
    }),
    // to accept or reject the service requests for accountant
    accountantServiceRequestStatus: builder.mutation({
      query: ({ patch }) => ({
        url: "accountantServiceRequestStatus",
        method: "POST",
        body: patch,
      }),
    }),
    // to update user profile details
    updateProfileDetails: builder.mutation({
      query: ({ patch }) => ({
        url: "user/profile",
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["profileData"],
    }),
    // to update the current user password
    updateCurrentPassword: builder.mutation({
      query: ({ patch }) => ({
        url: "/user/profile/changePassword",
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["profileData"],
    }),
    // to assign service request to accountant from service manager
    assignServiceRequest: builder.mutation({
      query: ({ patch }) => ({
        url: "serviceManagerServiceRequestAssignment",
        method: "POST",
        body: patch,
      }),
    }),
    countryListAPI: builder.query<PostsResponse, { countryList: string[] }>({
      query: ({ countryList }) => {
        const formattedList = JSON.stringify(countryList); // Convert array to JSON string
        return `phoneNumbervalidation?countryList=${formattedList}`;
      },
    }),

    getAssessment: builder.query<PostsResponse, string | undefined>({
      query: (assessmentYear) =>
        `selfAssessment?assessment_type=SELF_ASSESSMENT&assessment_year=${assessmentYear || localStorage.getItem(StorageConstants.ASSESSMENT_YEAR)}&assessment_id=${localStorage.getItem(StorageConstants.ASSESSMENT_ID) || null}`,
      providesTags: ["formsData"],
    }),
    getHmrcAuthStatus: builder.query<AuthStatusResponse, void>({
      query: () => "hmrc/isAuthorised",
    }),
    assessment: builder.mutation({
      query: ({ patch, assessment_id, subFormId }) => ({
        url: `selfAssessment?assessment_id=${assessment_id}&subFormId=${subFormId}`,
        method: "POST",
        body: patch,
      }),
      invalidatesTags: ["formsData"],
    }),
    assessmentSubForm: builder.mutation({
      query: ({ assessment_id, questionKey }) => ({
        url: `selfAssessment/subForm?assessment_id=${assessment_id}&questionKey=${questionKey}`,
        method: "POST",
      }),
      invalidatesTags: ["formsData"],
    }),
    deleteAssessmentSubForm: builder.mutation({
      query: ({ assessment_id, questionKey, subFormId }) => ({
        url: `selfAssessment/subForm?assessment_id=${assessment_id}&questionKey=${questionKey}&subFormId=${subFormId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["formsData"],
    }),
    hmrcTokenAuth: builder.mutation({
      query: ({ patch }) => ({
        url: "hmrc/oauth/token",
        method: "POST",
        body: patch,
      }),
    }),
    getAssessmentProgress: builder.query<PostsResponse, void>({
      query: () => "assessmentProgress?assessment_type=SELF_ASSESSMENT",
      providesTags: ["formsData"],
    }),
    adminOverviewCounts: builder.query<PostsResponse, void>({
      query: () => "admin/count",
    }),
    businessListing: builder.query<
      PostsResponse,
      { pageNo: number; limit: number; search: string; filter: string }
    >({
      query: ({ pageNo, limit, search, filter }) =>
        `admin/businessList?pageNo=${pageNo}&limit=${limit}&search=${search}&filter=${filter}`,
    }),
    accountantsListing: builder.query<
      PostsResponse,
      { pageNo: number; limit: number; search: string; filter: string }
    >({
      query: ({ pageNo, limit, search, filter }) =>
        `accountantsList?pageNo=${pageNo}&limit=${limit}&search=${search}&filter=${filter}`,
    }),
    allAccountantListing: builder.query<PostsResponse, void>({
      query: () => "accountantsList",
    }),
    // get user dashboard details
    userDetails: builder.query<PostsResponse, void>({
      query: () => "dashboard/intialDashboard",
    }),
    // get important events details
    eventDetails: builder.query<PostsResponse, void>({
      query: () => "events",
    }),
    // get assessment status details for admin
    assessmentStatus: builder.query<
      PostsResponse,
      { pageNo: number; limit: number; filter: string }
    >({
      query: ({ pageNo, limit, filter }) =>
        `assessmentStatus?pageNo=${pageNo}&limit=${limit}&filter=${filter}`,
    }),
    // list all business for admin dropdown
    allBusinessListing: builder.query<PostsResponse, void>({
      query: () => "admin/businessList",
    }),
    // get counts for accountant dashboard
    accountantOverviewCount: builder.query<PostsResponse, { userId: any }>({
      query: ({ userId }) => `accountantCount?id=${userId}`,
    }),
    // to get recent activities of accountant
    accountantActivityListing: builder.query<
      PostsResponse,
      { id: any; pageNo: number; limit: number; AccStatus: string; search: string }
    >({
      query: ({ id, pageNo, limit, AccStatus, search }) =>
        `accountantActivity?id=${id}&pageNo=${pageNo}&limit=${limit}&AccStatus=${AccStatus}&search=${search}`,
    }),
    // to get accountant service requests
    accountantServiceRequests: builder.query<
      PostsResponse,
      { id: any; pageNo: number; limit: number }
    >({
      query: ({ id, pageNo, limit }) =>
        `accountantServiceRequest?id=${id}&pageNo=${pageNo}&limit=${limit}`,
    }),
    // get overview counts of service manager
    serviceManagerOverviewCount: builder.query<PostsResponse, void>({
      query: () => "serviceManagerOverviewCount",
    }),
    // to get available accountants
    availableAccountants: builder.query<
      PostsResponse,
      { pageNo: number; limit: number; filter: string }
    >({
      query: ({ pageNo, limit, filter }) =>
        `serviceManagerAccountantListing?pageNo=${pageNo}&limit=${limit}&filter=${filter}`,
    }),
    // to get service requests for service manager dashboard
    serviceManagerServiceRequests: builder.query<PostsResponse, { pageNo: number; limit: number }>({
      query: ({ pageNo, limit }) => `serviceManagerServiceRequest?pageNo=${pageNo}&limit=${limit}`,
    }),
    // to get recent activities for service manager
    serviceManagerActivityListing: builder.query<
      PostsResponse,
      { pageNo: number; limit: number; AccStatus: string; search: string }
    >({
      query: ({ pageNo, limit, AccStatus, search }) =>
        `serviceManagerActivity?pageNo=${pageNo}&limit=${limit}&AccStatus=${AccStatus}&search=${search}`,
    }),
    // to get user profile settings details
    userProfileSettingsDetails: builder.query<PostsResponse, void>({
      query: () => "user/profile",
      providesTags: ["profileData"],
    }),
    // to get all available accountants for service manager to assign
    allAvailableAccountantsList: builder.query<PostsResponse, void>({
      query: () => "serviceManagerAccountantListing",
    }),

    // get message and post message apis
    getMessages: builder.mutation({
      query: ({ assessment_id, questionKey, subFormId, rowId }) => ({
        url: `selfAssessment/question/messages?assessment_id=${assessment_id}&questionKey=${questionKey}&subFormId=${subFormId}&rowId=${rowId}`,
      }),
    }),

    postMessages: builder.mutation({
      query: ({ patch, assessment_id, subFormId, rowId }) => ({
        url: `selfAssessment/question/messages?assessment_id=${assessment_id}&subFormId=${subFormId}&rowId=${rowId}`,
        method: "POST",
        body: patch,
      }),
    }),
    serviceManaging: builder.mutation({
      query: (patch) => ({
        url: "serviceManaging",
        method: "POST",
        body: patch,
      }),
      invalidatesTags: ["formsData"],
    }),
    taxCalculation: builder.mutation({
      query: ({ assessment_id, assessment_year, isFinalized }) => ({
        url: `selfAssessment/taxCalculation?assessment_id=${assessment_id}&assessment_year=${assessment_year}&isFinalized=${isFinalized}`,
        method: "GET",
      }),
    }),
    customerZoomDetails: builder.mutation({
      query: ({ pageNo, limit }) => ({
        url: `zoomDetails/?pageNo=${pageNo}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getTransitionLimit: builder.mutation({
      query: ({ assessment_id }) => ({
        url: `selfAssessment/status/transition/limit?assessment_id=${assessment_id}`,
        method: "GET",
      }),
    }),
    transition: builder.mutation({
      query: ({ patch, assessmentId }) => ({
        url: `selfAssessment/status/transition?assessment_id=${assessmentId}`,
        method: "POST",
        body: patch,
      }),
    }),
    //for admin config settings
    adminConfig: builder.mutation({
      query: ({ patch }) => ({
        url: "configSetting",
        method: "POST",
        body: patch,
      }),
      invalidatesTags: ["configSettings"],
    }),
    getProximitySummary: builder.query<PostsResponse, void>({
      query: () => "configSetting",
      providesTags: ["configSettings"],
    }),
    // to do Stripe payment request
    stripePayment: builder.mutation({
      query: ({ patch }) => ({
        url: "payment",
        method: "POST",
        body: patch,
      }),
    }),
    // get available accountants or bookkeepers for assign requests
    getAvailableAccountantOrBookkeeper: builder.query<PostsResponse, { review_type: any }>({
      query: ({ review_type }) => `accountantOrBookkeeper?review_type=${review_type}`,
    }),

    // to do update accountant status
    accountantStatusUpdate: builder.mutation({
      query: ({ patch }) => ({
        url: "accountantStatus",
        method: "PUT",
        body: patch,
      }),
    }),
    // to get status of accountant
    getAccountantStatus: builder.query<PostsResponse, void>({
      query: () => "accountantStatus",
    }),

    // to get past tax filings for customer
    getPastTaxFilings: builder.query<PostsResponse, void>({
      query: () => "pastTaxFilings",
    }),
    adminPaymentCardDelete: builder.mutation({
      query: ({ id, category }: { id: string; category: string }) => ({
        url: "configSetting",
        method: "DELETE",
        body: { id, category }, // Include the payload here
        headers: {
          "Content-Type": "application/json", // Ensure the server interprets it as JSON
        },
      }),
      invalidatesTags: ["configSettings"],
    }),

    //Get the sandbox settings
    sandboxSettings: builder.query<PostsResponse, void>({
      query: () => "sandboxSetting",
      providesTags: ["sandboxSettings"],
    }),

    //Post sandbox settings
    editSandboxSettings: builder.mutation({
      query: ({ patch }) => ({
        url: "sandboxSetting",
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: ["sandboxSettings"],
    }),

    // Get all assessment counts for admin in barchart
    adminAssessmnetCount: builder.query<PostsResponse, void>({
      query: () => "admin/assessmentCount",
    }),
    // to do Stripe payment checkout
    checkoutSession: builder.mutation({
      query: ({ patch }) => ({
        url: "checkoutSession",
        method: "POST",
        body: patch,
      }),
    }),
    // to get payment config settings
    paymentConfig: builder.query<PostsResponse, void>({
      query: () => "paymentConfig",
    }),
    // to get In app Notification
    getNotifications: builder.query<PostsResponse, void>({
      query: () => "notifications",
    }),
    // to mark the notification as read
    markNotifications: builder.mutation({
      query: ({ patch }) => ({
        url: "notifications/mark",
        method: "POST",
        body: patch,
      }),
    }),

    // To get customers listing for admin
    customerListing: builder.query<
      PostsResponse,
      { pageNo: number; limit: number; search: string; filter: string }
    >({
      query: ({ pageNo, limit, search, filter }) =>
        `admin/customerList?pageNo=${pageNo}&limit=${limit}&search=${search}&filter=${filter}`,
    }),

    // To get all customers listing for admin to export
    allCustomerListing: builder.query<PostsResponse, void>({
      query: () => "admin/customerList",
    }),

    // to add new events/important hmrc dates
    addNewEvents: builder.mutation({
      query: ({ patch }) => ({
        url: "events",
        method: "POST",
        body: patch,
      }),
    }),
  }),
});

export const {
  useAssessmentMutation,
  useGetAssessmentQuery,
  useLoginMutation,
  useRegisterMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetAssessmentProgressQuery,
  useCountryListAPIQuery,
  useGetHmrcAuthStatusQuery,
  useHmrcTokenAuthMutation,
  useAdminOverviewCountsQuery,
  useBusinessListingQuery,
  useAssessmentSubFormMutation,
  useDeleteAssessmentSubFormMutation,
  useAdminPaymentCardDeleteMutation,
  useAccountantsListingQuery,
  useUserDetailsQuery,
  useEventDetailsQuery,
  useAssessmentStatusQuery,
  useAllBusinessListingQuery,
  useBusinessReportsMutation,
  useAddBusinessMutation,
  useAddAccountantMutation,
  useAccountantOverviewCountQuery,
  useAccountantReportsMutation,
  useAccountantActivityListingQuery,
  useAccountantServiceRequestsQuery,
  useServiceManagerOverviewCountQuery,
  useAvailableAccountantsQuery,
  useAllAccountantListingQuery,
  useServiceManagerServiceRequestsQuery,
  useServiceManagerActivityListingQuery,
  useAccountantServiceRequestStatusMutation,
  useUserProfileSettingsDetailsQuery,
  useUpdateProfileDetailsMutation,
  useUpdateCurrentPasswordMutation,
  useAllAvailableAccountantsListQuery,
  useAssignServiceRequestMutation,
  // useCustomerReviewRequestMutation,
  useServiceManagingMutation,
  useGetMessagesMutation,
  usePostMessagesMutation,
  useGetTransitionLimitMutation,
  useTransitionMutation,
  useAdminConfigMutation,
  useGetProximitySummaryQuery,
  useStripePaymentMutation,
  useGetAvailableAccountantOrBookkeeperQuery,
  useAccountantStatusUpdateMutation,
  useGetAccountantStatusQuery,
  useGetPastTaxFilingsQuery,
  useAdminAssessmnetCountQuery,
  useSandboxSettingsQuery,
  useEditSandboxSettingsMutation,
  useCheckoutSessionMutation,
  useCustomerListingQuery,
  usePaymentConfigQuery,
  useTaxCalculationMutation,
  useCustomerZoomDetailsMutation,
  useGetNotificationsQuery,
  useMarkNotificationsMutation,
  useAllCustomerListingQuery,
  useAddNewEventsMutation,
} = taxdApi;
