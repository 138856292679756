import {
  Box,
  SimpleGrid,
  TextInput,
  Button,
  Stack,
  Modal,
  rem,
  useMantineTheme,
  Group,
  Select,
} from "@mantine/core";
import { useState, useEffect } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { CONSTANT } from "../../../utility/constant";
import { useCountryListAPIQuery, useUpdateProfileDetailsMutation } from "../../../store/api";

const AddressUpdateModal = ({ close, opened, profileDetails }) => {
  const theme = useMantineTheme();
  const [updateUserAddress, { isLoading: visible }] = useUpdateProfileDetailsMutation();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const { data: countryData } = useCountryListAPIQuery({ countryList: [] });
  const countryList = countryData?.data;

  useEffect(() => {
    if (countryList) {
      const formattedCountries = countryList?.map((country) => ({
        value: country?.countryCode,
        label: `${country?.flagIcon} (${country?.phoneCode}) ${country?.countryName}`,
        countryName: country?.countryName,
      }));
      setCountries(formattedCountries);
      if (profileDetails) {
        const selected = formattedCountries?.find(
          (country) => country.countryName === profileDetails?.address?.country,
        );
        setSelectedCountry(selected);
      }
    }
  }, [countryData, profileDetails]);

  const handleCountryChange = (value) => {
    const selected = countries?.find((country) => country.value === value);
    setSelectedCountry(selected);
  };

  const form = useForm({
    mode: "controlled",
    initialValues: {
      city: "",
      street: "",
      zipCode: "",
    },
    validate: {
      city: isNotEmpty("city cannot be empty"),
      street: isNotEmpty("street cannot be empty"),
      zipCode: isNotEmpty("Postal code cannot be empty"),
    },
    validateInputOnBlur: true,
  });

  const handleAddressUpdate = async () => {
    const { city, street, zipCode } = form.values;
    await updateUserAddress({
      patch: {
        userProfile: {
          address: {
            street,
            city,
            country: selectedCountry?.countryName,
            zipCode: zipCode.trim(),
          },
        },
      },
    })
      .unwrap()
      .then((res) => {
        notifications.show({
          title: CONSTANT.SUCCESS,
          message: res?.message || CONSTANT.SUCCESS,
          color: "Green",
          position: "top-right",
        });
        form.reset();
        close();
      })
      .catch((err) => {
        notifications.show({
          title: CONSTANT.ERROR,
          message: err?.data?.message || CONSTANT.SWW,
          color: "red",
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    if (profileDetails?.address) {
      const clonedAddress = JSON.parse(JSON.stringify(profileDetails.address));
      form.setInitialValues(clonedAddress);
      form.setValues(clonedAddress);
    }
  }, [profileDetails]);

  return (
    <Modal
      opened={opened}
      onClose={close}
      title="Update Address Details"
      size="lg"
      radius={20}
      centered>
      {/* Modal content */}
      <Box mb="md">
        <form
          style={{ width: "100%" }}
          onSubmit={form.onSubmit(() => {
            handleAddressUpdate();
          })}>
          <Stack>
            <SimpleGrid
              cols={{ base: 1, sm: 2, lg: 2 }}
              spacing={{ base: 10 }}
              verticalSpacing={{ base: "sm" }}>
              <Select
                label={CONSTANT.SELECT_COUNTRY}
                placeholder={CONSTANT.CHOOSE_COUNTRY}
                data={countries}
                value={selectedCountry?.value || ""}
                onChange={handleCountryChange}
                searchable
                nothingFoundMessage="No options"
                radius="md"
                size="md"
                mt="md"
                c="dimmed"
              />
              <TextInput
                label="City/State"
                placeholder="City/State name"
                withAsterisk
                radius="md"
                size="md"
                mt="md"
                c="dimmed"
                defaultValue={profileDetails?.address?.city}
                // value={form.values.city}
                onChange={(event) => {
                  form.setFieldValue("city", event.target.value);
                }}
                error={form.errors.city ?? ""}
              />
            </SimpleGrid>
            <Stack>
              <TextInput
                withAsterisk
                label="HouseNo./Street"
                placeholder="Full Address"
                radius="md"
                size="md"
                mt="sm"
                c="dimmed"
                defaultValue={profileDetails?.address?.street}
                // value={form.values.street}
                onChange={(event) => {
                  form.setFieldValue("street", event.target.value);
                }}
                error={form.errors.street ?? ""}
              />
              <TextInput
                withAsterisk
                label="Postal Code"
                placeholder="Your Postal Code"
                radius="md"
                size="md"
                mt="sm"
                c="dimmed"
                defaultValue={profileDetails?.address?.zipCode}
                // value={form.values.zipCode}
                onChange={(event) => {
                  form.setFieldValue("zipCode", event.target.value.toUpperCase());
                }}
                error={form.errors.zipCode ?? ""}
                styles={{
                  input: { textTransform: "uppercase", fontSize: rem(14) }, // Optional: visually enforce uppercase style
                }}
              />
            </Stack>
            <Group justify="flex-end">
              <Button
                type="submit"
                radius="md"
                w="100%"
                mt="sm"
                maw="40%"
                bg={theme.colors.deepBlue[9]}
                loading={visible}
                c="white"
                h={rem(50)}>
                Update
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default AddressUpdateModal;
