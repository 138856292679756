import { Navigate, Outlet } from "react-router-dom";
import { ROUTES, StorageConstants } from "../../utility/constant";
import UseIsAuthenticated from "../../hooks/UseIsAuthenticated";

export const ProtectedRoleRoute = ({ allowedRoles = [] }: { allowedRoles: string[] }) => {
  const isAuth = UseIsAuthenticated();
  const currentUserRole = localStorage.getItem(StorageConstants.USER_ROLE);
  const restrictedRoutes = [
    ROUTES.ADMIN_LOGIN,
    ROUTES.LOGIN,
    ROUTES.SERVICE_MANAGER_LOGIN,
    ROUTES.ACCOUNTANT_LOGIN,
    ROUTES.VERIFY_OTP,
    ROUTES.ADD_BUSINESS_LANDING,
    ROUTES.FORGOT_PASSWORD,
    ROUTES.SIGNUP,
    ROUTES.LANDING,
    ROUTES.ACCOUNTANT_FORGOT_PASSWORD,
  ];

  const currentPath = window.location.pathname;

  // If the user is authenticated and trying to access a restricted login route, redirect to a previous route
  if (
    isAuth &&
    restrictedRoutes.includes(window.location.pathname as (typeof restrictedRoutes)[number])
  ) {
    window.history.back();
  }

  //  Allow access if the current user role matches an allowed role and the user is authenticated
  if (isAuth && allowedRoles.includes(currentUserRole)) {
    return <Outlet />;
  }

  //  Allow access to restricted routes if currentUserRole is null
  if (
    !currentUserRole &&
    restrictedRoutes.includes(currentPath as (typeof restrictedRoutes)[number])
  ) {
    return <Outlet />;
  }

  //  Redirect to `*` if the current user role does not match an allowed role
  if (isAuth && !allowedRoles.includes(currentUserRole)) {
    return <Navigate to="*" replace />;
  }

  // Default fallback (shouldn't normally reach here)
  return <Navigate to={ROUTES.LOGIN} replace />;
};
