// subFormSlice.js
import { createSlice } from "@reduxjs/toolkit";

const subFormSlice = createSlice({
  name: "subForm",
  initialState: {
    subFormId: null,
  },
  reducers: {
    addSubFormId: (state, action) => {
      state.subFormId = action.payload;
    },
    clearSubFormId: (state) => {
      state.subFormId = null;
    },
  },
});

export const { addSubFormId, clearSubFormId } = subFormSlice.actions;

export default subFormSlice.reducer;
