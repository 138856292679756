import { Button, useMantineTheme } from "@mantine/core";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React from "react";
import { CONSTANT } from "../../utility/constant";

const Forbidden = () => {
  const theme = useMantineTheme();
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Oops...</h1>
      <h3>{CONSTANT.NOT_FOUND}</h3>
      <p>{CONSTANT.NOT_FOUND_DESCRIPTION}</p>
      <Button
        color={theme.colors.deepBlue[9]}
        leftSection={<IconArrowNarrowLeft size={16} />} // Icon with the size adjustment
        onClick={() => window.history.back()}>
        {CONSTANT.GO_BACK}
      </Button>
    </div>
  );
};

export default Forbidden;
