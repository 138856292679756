export const CONSTANT = Object.freeze({
  EMAIL: "Email",
  DASHBOARD: "Dashboard",
  SUBMIT: "Submit",
  SUCCESS: "Success",
  ERROR: "Error",
  SWW: "Something went wrong.",
  ZIP_CODE_ERROR: "You need to complete your profile details before scheduling a call",
  SIGNOUT: "Sign Out",
  LOGIN: "Login",
  SIGNUP: "Sign up",
  SIGNIN: "Sign in",
  USERNAME: "Username",
  PASSWORD: "Password",
  PASSWORDMINLEN: 8,
  CONFIRMPASSWORD: "Confirm Password",
  FIRSTNAME: "First Name",
  LASTNAME: "Last Name",
  PHONENUMBER: "Phone Number",
  EMAILADDRESS: "Email Address",
  FORGOTPASSWORD: "Forgot Password",
  TERMS: "Terms of service",
  AND: "and",
  PRIVACY: "Privacy policy",
  GETSTARTED: "Get Started",
  CREATE_ACC: "Create account",
  PAST_TAX_FILE: "Past Tax Filings",
  OVERVIEW: "Overview",
  SEEALL: "See All",
  PERSONAL_TAX: "Personal Tax",
  LIMITED_COMPANY: "Limited Company",
  PARTNERSHIP_TAX: "Partnership Tax",
  INSIGHTS_GUIDES: "Insights & Guides",
  SETTINGS: "Settings",
  BUSINESSES: "Businesses",
  ACCOUNTANTS: "Accountants",
  CUSTOMERS: "Customers",
  ASSESSMENT_STATUS: "Assessments Status",
  REVIEWS: "Reviews",
  REPORTS: "Reports",
  CLIENTS: "Clients",
  INSIGHTS_AND_DUIDES: "Insights & Guides",
  IMPORTANT_DATES: "Important Dates",
  TAX_FILLING: "Tax Filings",
  CHAT_HISTORY: "Chat History",
  RECENT_REVIEWS: "Recent Reviews",
  OTHER_REVIEWS: "Other Reviews",
  SERVICE_REQUESTS: "Service Requests",
  ACCEPT: "Accept",
  REJECT: "Reject",
  JOIN_MEETING: "Join Meeting",
  START_MEETING: "Start Meeting",
  START_CALL: "Start Call",
  ACCOUNTANT_PROGRESS: "Accountant Progress",
  VERIFY_OTP: "Verify otp",
  RESEND_OTP: "Resend OTP",
  VERIFY_EMAIL: "Verify Email",
  RESET_PASSWORD: "Reset Password",
  ADMIN_SIGNIN: "Admin Sign in",
  AUTH_TOKEN: "authToken",
  ADD_BUSINESS: "Add Business",
  ADD_ACCOUNTANT: "Add Accountant",
  CREATE_ACCOUNT: "Create Account",
  ACCOUNTANT_SIGNIN: "Accountant Sign in",
  ACCOUNTANT_PASSWORD_RESET: "Accountant Password Reset",
  IN_REVIEW: "In Review",
  COMPLETED: "Completed",
  COMPLETE: "Complete",
  BOOK_A_CALL: "Book a Call",
  CANCELLED: "Cancelled",
  HMRC_IMPORTANT_DATES: "HMRC Important Dates",
  EMPLOYMENT: "Employment",
  INTRODUCTION: "Introduction",
  NOT_STARTED: "Not Started",
  COMPLETED_STATUS: "COMPLETED",
  IN_REVIEW_STATUS: "IN_REVIEW",
  ALL: "All",
  UK_PROPERTY: "UK property",
  SERVICE_MANAGER_SIGNIN: "Service Manager Sign in",
  PAYMENTS: "Payments",
  RECENT_ACTIVITY: "Recent Activity",
  AVAILABLE_ACCOUNTANTS: "Available Accountants",
  ASSIGN: "Assign",
  CHAT: "Chat",
  STARTING_YOUR_TAX_RETURN: "Starting your tax return",
  OTHER_UK_INCOME: "Other UK Income",
  SELF_EMPLOYMENT_FULL: "Self-employment (full)",
  CAPITAL_GAINS_TAX_SUMMARY: "Capital Gains Tax summary",
  FOREIGN: "Foreign",
  CAPITAL_GAINS: "Capital gains",
  BLOCKED: "Blocked",
  IN_PROGRESS: "In Progress",
  SELECT_COUNTRY: "Select Country",
  CHOOSE_COUNTRY: "Choose a Country",
  ENTER_PHONE_NUMBER: "Enter Phone Number",
  NEXT: "Next",
  UPDATE: "Update",
  ADD_NEW: "Add New",
  EDIT: "Edit",
  DELETE: "Delete",
  EDIT_MY_REVIEW: "Edit my review",
  DELETE_MY_REVIEW: "Delete my review",
  UNSUPPORTED_QUESTION_TYPE: "Unsupported Question Type",
  VERIFY_OTP_DESCRIPTION:
    "A 6-digit OTP has been sent to your registered email. If it’s not in your inbox, please take a quick look in your spam or junk folder.",
  IGNORE: "Ignore",
  GO_BACK: "Go Back",
  NOT_FOUND_DESCRIPTION: "We are sorry but the page you are looking for does not exist.",
  NOT_FOUND: "404 page not found",
  REVIEW: "Review",
  GO_TO_DASHBOARD: "Go to Dashboard",
  SEND_FOR_REVIEW: "Send for Review",
  CALL_ONLY: "CALL_ONLY",
  CONTINUE: "Continue",
  SUBMIT_REVIEW: "Submit Review",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  SELF_ASSESSMENT: "Self Assessment",
  STATUS_UPDATE: "Status updated successfully",
  PAST_TAX_LOGO_TITLE: "You don’t have any previously filed tax.",
  PAST_TAX_LOGO_BODY: " When you do, you will see them here.",

  // Assessment constants
  ASSESSMENT_YEAR_LABEL: "Assessment Year",
  ASSESSMENT_TYPE_LABEL: "Assessment Type",
  CREATED_DATE: "Created Date",
  UPDATED_DATE: "Updated Date",

  PROCEED_TO_TAX_E_FILING: "Proceed to Tax E-Filing",
  EXCLUSIVE_OF_ALL_TAXES: "Exclusive of all taxes",
  TOTAL: "Total",
  SUMMARY: "Summary",
  PAY_YOUR_LIBAILITY: "Pay Your Libaility",
  // Profile constants
  PROVIDE_ALL_THE_DETAILS_REQUIRED: "Provide all the details below required",
  STATUS: "Status",
  ACTIVE: "Active",
  PROFILE_MAX_SIZE: "PNG, JPEG (Max 2 MB)",
  REMOVE_PICTURE: "Remove Picture",
  UPLOAD_NEW_PICTURE: "Upload new picture",
  ACCOUNT: "Account",
  CHANGE: "Change",
  CHANGE_PASSWORD: "Change Password",
  PASSWORD_DESCRIPTION: "Make your password stronger, or change it if someone else knows it",
  ADDRESS: "Address",
  PROVIDE_ADDRESS: "Provide the address below",
  COUNTRY: "Country",
  CITY_STATE: "City/State",
  HOUSE_STREET_NO: "HouseNo./Street",
  POSTAL_CODE: "Postal Code",
  PROCEED_TO_PAYMENT: "Proceed to Payment",

  // accountant constants
  ACCOUNTANT_REVIEW: "Accountant Review",
  FINALISING_YOUR_RETURN: "Finalising your return",
  RESET: "Reset",
  LOGOUT: "Logout",
  NO_OPTIONS_FOUND: "Not available",
  ASSESSMENT_YEAR: "Tax Year",
  PAY_AGAIN: "Pay Again",
  REFUND: "Refund",
  LIABILITY: "Liability",
});

//
export const ROUTES = Object.freeze({
  // Customer Routes

  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  SIGNUP: "/signup",
  ASSESSMENT: "/assessment",
  OVERVIEW: "/overview",
  PROFILE: "/profile",
  SETTINGS: "/settings",
  CUSTOMER_PROFILE: "/customerprofile",
  PERSONAL_TAX: "/personaltax",
  PARTNERSHIP_TAX: "/partnershiptax",
  INSIGHTS_GUIDES: "/insights-guides",
  LIMITED_COMPANY: "/limited-company",
  BUSINESSES: "/businesses",
  ADD_YOUR_BUSINESS: "/add-your-business",
  ACCOUNTANTS: "/accountants",
  CUSTOMERS: "/customers",
  VERIFY_OTP: "/verify-otp",
  FORGOT_PASSWORD: "/forgot-password",
  LANDING: "/get-started",
  INTERMEDIATE_TAX_PAGE: "/intermediate-tax-page",
  PRIVACY_POLICY: "/privacy-policy",
  COMING_SOON: "/coming-soon",
  HMRC_DATES: "/hmrc/dates",
  ADD_BUSINESS_LANDING: "/add-business",
  FINAL_SUBMIT: "/final-submit",
  PAYMENT_PAGE: "/payment-page",
  ZOOM_MEETING: "/zoom",
  ALL_PAST_TAX: "/pastTaxFilings",

  // Admin Routes
  ADMIN_LOGIN: "/admin/login",
  ADMIN_BUSINESSES: "/admin/businesses",
  ADMIN_ACCOUNTANTS: "/admin/accountants",
  ADMIN_CLIENTS: "/admin/clients",
  ADMIN_OVERVIEW: "/admin/overview",
  ADMIN_SETTINGS: "/admin/settings",
  ADMIN_ASSESSMENT_STATUS: "/admin/assessment-status",
  ADMIN_PROFILE: "/admin/profile",
  ADMIN_INDIVIDUALS: "/admin/clients:individuals",
  ADMIN_COMPANY: "/admin/clients:company",
  ADMIN_PARTERSHIPS: "/admin/clients:partnerships",
  ASMIN_INSIGHTS_AND_DUIDES: "/admin/insights-guides",
  ADMIN_CUSTOMERS: "/admin/customers",
  ADMIN_ADD_BUSINESS: "/admin/add-business",
  ADMIN_ADD_ACCOUNTANTS: "/admin/add-accountants",
  ADMIN_HMRC_DATE_PAGE: "/admin/hmrc/dates",

  // Accountant Routes
  ACCOUNTANT_LOGIN: "/accountant/login",
  ACCOUNTANT_PASSWORD_RESET: "/accountant/password-reset",
  ACCOUNTANT_OVERVIEW: "/accountant/overview",
  ACCOUNTANT_TAX_FILLING: "/accountant/taxfilling",
  ACCOUNTANT_CHAT_HISTORY: "/accountanta/chat-history",
  ACCOUNTANTA_INSIGHTS_GUIDES: "/accountanta/insights-guides",
  ACCOUNTANTA_SETTINGS: "/accountanta/setting",
  ACCOUNTANT_HMRC_DATE_PAGE: "/accountant/hmrc/dates",
  ACCOUNTANT_REVIEWS: "/accountant/reviews",
  ACCOUNTANT_SERVICE_REQUESTS: "/accountant/service-requests",
  ACCOUNTANT_CUSTOMER_PROFILE: "/accountant/profile",
  ACCOUNTANT_FORGOT_PASSWORD: "/accountant/forgot-password",
  ACCOUNTANT_SETTINGS: "/accountant/settings",

  // Service manager Routes
  SERVICE_MANAGER_LOGIN: "/service-manager/login",
  SERVICE_MANAGER_OVERVIEW: "/service-manager/overview",
  SERVICE_MANAGER_SETTINGS: "/service-manager/settings",
  AVAILABLE_ACCOUNTANTS: "/available-accountants",
  SERVICE_REQUESTS: "/service-requests",
  RECENT_ACTIVITIES: "/recent-activities",
  SERVICE_MANAGER_CUSTOMER_PROFILE: "/service-manager/profile",
  SERVICE_MANAGER_HMRC_DATE_PAGE: "/service-manager/hmrc/dates",

  //Forbidden route
  FORBIDDEN: "/forbidden",
});

export const ERROR_MESSAGE = Object.freeze({
  INVALID_EMAIL: "Invalid email",
  INVALID_PASSWORD: "Password cannot be empty",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password cannot be empty",
  MISMATCHED_PASSWORD: "Passwords did not match",
  PASSWORD_REGEX:
    "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (e.g., !@#$%^&*).",
  INVALID_UPPERCASE: "Password should include at least one uppercase letter",
  INVALID_LOWERCASE: "Password should include at least one lowercase letter",
  INVALID_DIGIT: "Password should include at least one digit",
  INVALID_SPECIALCHAR: "Password should include at least one special character",
  CURRENT_NEW_PASSWORD_MATCH: "Current and new passwords should be different",
  INVALID_FIRST_NAME: "First name cannot be empty",
  INVALID_LAST_NAME: "Last name cannot be empty",
  INVALID_PHONE_NUMBER: "Phone number cannot be empty",
  INVALID_OTP: "OTP cannot be empty",
  VALID_OTP_MSG: "OTP should include 6 digits",
  PLEASE_ENTER_VALID_PHONE_NUMBER: "Please enter a valid phone number",
  PLEASE_SELECT_COUNTRY_CODE: "Please select a country code",
});

export const SUCCESS_MESSAGE = Object.freeze({
  ASSESSMENT_COMPLETED_SUCCESSFULLY: "Assessment completed successfully",
  REQUEST_SENT_SUCCESSFULLY: "Request sent successfully",
});

export const AUTH_TEXTS = Object.freeze({
  AND: "and",
  SIGNING_TERMS: "By signing up you agree to Tax In Minutes ",
  ALREADY_HAVE_ACCOUNT: "Already have an account? ",
  NEW_TO_TIM: "New to Tax In Minutes ",
  COPYRIGHT_TEXT: `©${new Date().getUTCFullYear()} TIM, Inc. All Rights Reserved.`,
});

export const USER_TYPE = Object.freeze({
  CUSTOMER: "customer",
  ADMIN: "super admin",
  ACCOUNTANT: "accountant",
  BUSINESS: "business",
  SERVICE_MANAGER: "service manager",
  BOOKKEEPER: "bookkeeper",
});

export const TRANSITION_STATES = Object.freeze({
  MOVE_TO_CUSTOMER: "MOVE_TO_CUSTOMER",
  MOVE_TO_REVIEWER: "MOVE_TO_REVIEWER",
  MARK_AS_COMPLETED: "MARK_AS_COMPLETED",
});

export const REGEX = Object.freeze({
  // PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_()])[A-Za-z\d@$!%*?&_()]{8,}$/,
  PASSWORD_REGEX:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_()[\]{}:;'"<>,.?/~`^|\\+=#-])[A-Za-z\d@$!%*?&_()[\]{}:;'"<>,.?/~`^|\\+=#-]{8,12}$/,
  EMAIL_REGEX: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  UPPERCASEREGEX: /^(?=.*?[A-Z])/,
  LOWERCASEREGEX: /^(?=.*?[a-z])/,
  DIGITREGEX: /^(?=.*?[0-9])/,
  SPECIALCHARREGEX: /^(?=.*?[#?!@$%^&*-_])/,
  PASSWORD_COMPLEXITY_REGEX: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
});

export const MODAL_MSGS = Object.freeze({
  DELETE_EMPLOYMENT_TITLE: "Are you sure you want to delete this employment?",
  LOGOUT_TITLE: "Are you sure you want to Logout?",
  MODAL_YES: "Yes",
  MODAL_NO: "No",
  UPDATE_PROFILE_TITLE: "Update profile details",
  REMOVE_PROFILE_PICTURE: "Are you sure you want to remove your profile picture?",
  DELETE_ROW_TITLE: "Are you sure you want to delete this item?",
});

export const COPYRIGHT_TEXT = `©${new Date().getUTCFullYear()} TIM, Inc. All Rights Reserved.`;
export const SIGNING_TERMS = "By signing up you agree to Tax In Minutes ";
export const ALREADY_HAVE_ACCOUNT = "Already have an account? ";
export const NEW_TO_TIM = "New to Tax In Minutes ";

export const LandingScreenConstants = {
  PAGE_TITLE: "We Make",
  PAGE_TITLE_PART: "Progress",
  PAGE_SUBTITLE: "On Your Business",
  PAGE_DESCRIPTION:
    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  BUTTON_GET_STARTED: "Get Started",
  BUTTON_LEARN_MORE: "Learn More",
  NAV_SERVICES: "Services",
  NAV_INSIGHTS: "Insights",
  NAV_PRICING: "Pricing",
  NAV_CONTACT_US: "Contact us",
  LOGO_TEXT: "# TIM",
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
  BUSINESS: "Business",
};

export const QuestionTypeConstants = {
  SINGLE_CHOICE: "Single Choice",
  DATE: "Date",
  MULTI_Q: "Multi Q",
  PHONE: "Phone",
  MULTIPLE_CHOICE: "Multiple Choice",
  BOOLEAN: "Boolean",
  ADDRESS: "Address",
  BOOLEAN_MULTI: "Boolean Multi",
  MULTI_TOGGLE: "Multi Toggle",
  LINK_TEXT: "linkText",
  ROW: "Row",
  NUMERIC: "Numeric",
  FREE_TEXT: "Free Text",
  POST_CODE: "Postcode",
  UTR: "UTR",
  COUNTRY: "Country",
  TAX_YEAR: "Tax-year",
  OPTIONS: "Options",
  TOGGLE: "Toggle",
  MULTI_LINE: "Multi Line",
  DESCRIPTION: "Description",
  ARRAY_TEXT: "Array Text",
  DECLARATION: "Declaration",
  COUNTRY_LIST: "Country List",
  ARRAY: "Array",
};

export const StorageConstants = {
  ASSESSMENT_YEAR: "ASSESSMENT_YEAR",
  ASSESSMENT_ID: "ASSESSMENT_ID",
  ASSESSMENT_TYPE: "ASSESSMENT_TYPE",
  USER_EMAIL: "USER_EMAIL",
  USER_ID: "USER_ID",
  USER_ROLE: "USER_ROLE",
  SELECTED_TAX_YEAR: "SELECTED_TAX_YEAR",
  USER_ZIP_CODE: "USER_ZIP_CODE",
  SUB_FORM_ID: "SUB_FORM_ID",
};

export const AssessmentStatus = {
  IN_REVIEW: "IN_REVIEW",
  REVIEW_REQUESTED: "REVIEW_REQUESTED",
  APPROVAL_PENDING: "APPROVAL_PENDING",
  READY_TO_SUBMIT: "READY_TO_SUBMIT",
  ASSESSMENT_INPROGRESS: "ASSESSMENT_INPROGRESS",
  COMPLETED: "COMPLETED",
  ASSESSMENT_COMPLETED: "ASSESSMENT_COMPLETED",
  REQUEST_DECLINED: "REQUEST_DECLINED",
  REVIEW_AND_EDIT: "REVIEW_AND_EDIT",
  REVIEW_ONLY: "REVIEW_ONLY",
  IN_CALL: "IN_CALL",
};

export const paymentConstants = {
  CARDS: "Cards",
  PAYPAL: "PayPal",
  PAYMENT_METHOD: "Payment Method",
  SELECT_PAYMENT_METHOD: "Select Payment Method here",
  PAY_NOW: "Pay Now",
  PAYMENT_CHECKBOX_LABEL: "I hereby declare that the information provided is true and correct.",
  SELF_ASSESSMENT: "Self Assessment",
  SELF_ASSESSMENT_SUMMARY:
    "You can plan, prepare, and e-file your tax returns with trusted sources on TIM for free.",
  SELF_ASSESSMENT_COST: "£90",
  TOTAL_PAYMENT: "You have to pay",
  CARDHOLDER_INPUT_LABEL: "Enter the cardholder name",
  CARDHOLDER_INPUT_PLACEHOLDER: "Name on the Card",
  BACK_CONFIRMATION_LABEL: "Do you really want to cancel payment?",
  YES: "YES",
  NO: "NO",
  PAYMENT_SUCCESSFUL: "Payment successful!",
  PAYMENT_FAILED: "Payment failed. Please try again.",
  STRIPE_ELEMENT_ERROR: "Stripe or Elements is not loaded yet.",
  PROCEED_TO_PAYMENT: "Proceed to payment",
  REVIEW_CARD_TITLE: "Review with Accountants",
  CHAT_WITH_ACCOUNTANT: "Chat with Accountant",
  VIDEO_CALL_WITH_EXPERTS: "Video call with Experts",
  REVIEW_AND_EDIT_CARD_TITLE: "Review + Edit with Accountants",
};

export const PAYMENT_CONFIG_SETTINGS = {
  TITLE: "Title",
  DESCRIPTION: "Description",
  PRICE: "Price",
  FEATURES: "Features",
  ADD_FEATURE: "Add Feature",
  ADD_ANOTHER: "Add Another",
  TITLE_CARD: "Enter the title",
  DESCRIPTION_CARD: "Enter the description",
  PRICE_CARD: "Enter the price",
  FEATURES_CARD: "Enter a feature",
  SUBMIT: "Submit",
  PROMPT: "Are you sure you want to delete?",
  PAYMENT_SUBSCRIPTION_TEXT: "Payment Subscription",
  PAYMENT_CARD_DETAILS: "Payment Card Details",
  PAYMENT_SETTINGS: "Payment Settings",
  ADD: "Add",
  VIEW: "View",
  FORM_DATA: "Please fill all the mandatory fields in the form to submit.",
  FORM_TITLE_ERROR: "Title is required",
  FORM_DESCRIPTION_ERROR: "Description is required",
  FORM_PRICE_ERROR: "Price is required",
  FORM_FEATURE_ERROR: "Feature cannot be empty",
  AT_LEAST_ONE_FORM_REQUIRED: "At least one form is required.",
  DELETE_FORM: " Delete Form",
  NO_MATCHING_FOUND: "No matching component found",
};

export const REQUEST_TIMEOUT = {
  REQUEST_TIMEOUT_TEXT: "Request Timeout",
  EDIT: "Edit",
  VIEW: "View",
  SAVE: "Save",
  SERVICE_MANAGER_TEXT: "Service manager waiting time",
  NO_DATA: "No data available",
  WAITING_TIME_PLACEHOLDER: "Enter waiting time",
  WAIT_TIME: "Waiting time updated successfully!",
  FAILED_UPDATE_TIME: "Failed to update waiting time.",
  UPDATE_TIME_ERROR: "An error occurred while updating waiting time",
  FAILED_CARD_DELETE: "Failed to delete card.",
  WAITING_TIME_UPDATE: "Service Manager waiting time updated successfully!",
  WAITING_TIME_FORM_ERROR:
    "The waiting time field cannot be left empty. Please complete this field.",
};

export const configSettings = {
  NA: "N/A",
};

export const toastMessages = {
  DATA_SUBMITTED: "Data submitted successfully!",
  FAILED_SUBMIT: "Failed to Submit",
  DATA_UPDATED: "Data updated successfully!",
  FAILED_MESSAGE: "Failed to submit the form data",
  PAYMENT_CARD: "Payment card added successfully.",
  FORM_ERROR: "An error occurred while submitting the form data.",
  CARD_DELETED: "Card deleted successfully.",
  FAILED_CARD: "Failed to delete the card. Please try again",
  DATA_NOT_FOUND: "Data not found or invalid.",
};

export const ADMIN_CONFIG_SETTINGS = {
  ADMIN_CONFIG_TEXT: "Admin Configuration Settings",
  ADMIN_SANDBOX_TEXT: "Integration Settings",
  ADMIN_UPLOAD_TEMPLATE: "Upload Templates",
  TEMPLATE_INNER_TEXT: "Uploaded files:",
  UPLOAD_TEMPLATE_TEXT: "Upload",
  OTHER_SETTINGS_TEXT: "Other Settings",
  VIEW_BUTTON: "View",
  EDIT_BUTTON: "Edit",
  TEMPLATE_UPLOAD_TEXT: "Templates Settings",
  DISTANCE: "Find within the Distance (mi)",
  BUSINESSES: "Number of Businesses",
  ACCOUNTANTS: "Number of Accountants",
  BOOKKEEPERS: "Number of Bookkeepers",
  DISTANCE_CARD: "Enter distance",
  BUSINESS_CARD: "Enter the number of businesses",
  ACCOUNTANTS_CARD: "Enter number of accountants",
  BOOKKEEPERS_CARD: "Enter number of bookkeepers",
  SERVICE_MANAGER_WAITING_TIME: "Service manager waiting time",
  SUBMIT: "Submit",
  PROXIMITY_TEXT: "Proximity Adjustments",
  PROXIMITY_SUMMARY: "Proximity Summary",
  VIEW: "View",
  EDIT: "Edit",
  PROXIMITY_ADJUST_TEXT: "Adjust the proximity settings below:",
  LOADING_DATA: "Loading data or no data available...",
  PROXIMITY_SETTINGS: "Proximity Settings",
  PAYMENT_SETTINGS: "Payment Settings",
  SERVICE_METRICS: "Service Metrics",
  ASSESSMENT_YEAR: "Assessment Year",
  IMPORTANT_DATES: "Important Dates",
};

export const TAX_BREAK_DOWN = {
  REFUND: "Refund",
  LIABILITY: "Liability",
  TOTAL_TAX_INCOME: " Total taxable income",
  TOTAL_DEDUCTION: " Total deduction",
  TAX_CHARGED: "  Tax charged",
  TOTAL_TAX_DUE: " Total tax due",
};
