import { createSlice } from "@reduxjs/toolkit";
import { taxdApi } from "../api";

const initialState: any = {
  userLoginInfo: {}, // This will be persisted by redux-persist
};

export const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    // Action to handle logout
    logout: (state) => {
      state.userLoginInfo = {};
    },
    // Action to update tokens
    updateTokens: (state, { payload }) => {
      state.userLoginInfo = payload;
    },
  },
  extraReducers: (builder) => {
    // Handle login success by storing user information
    builder.addMatcher(taxdApi.endpoints.login.matchRejected, (state, { payload }) => {
      state.userLoginInfo = payload;
    });
    // Optionally handle login failure if needed
    builder.addMatcher(taxdApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      if (payload?.data?.validation_status !== 0) {
        state.userLoginInfo = payload;
      } else {
        state.userLoginInfo = {};
      }
    });
    builder.addMatcher(taxdApi.endpoints.verifyOtp.matchFulfilled, (state, { payload }) => {
      state.userLoginInfo = payload;
    });
  },
});
export const { logout, updateTokens } = loginSlice.actions;
export default loginSlice.reducer;
