import { lazy, Suspense } from "react";
import "./App.css";
import "react-phone-number-input/style.css";
import { Box, useMantineTheme } from "@mantine/core";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ROUTES, USER_TYPE } from "./utility/constant";
import CustomerProfile from "./pages/customer/customer-profile/CustomerProfile";

import {
  accountantNavbarLinkData,
  admiNavbarLinkData,
  customerNavbarLinkData,
  serviceManagerNavbarLinkData,
} from "./utility/assets/jsonData";
import { ProtectedRoleRoute } from "./component/auth-routes/AuthRoutes";
import ErrorBoundary from "./ErrorBoundary";
import Forbidden from "./pages/forbidden/Forbidden";

/* The code you provided is using lazy loading in a React application. Lazy loading is a technique used
to improve performance by splitting the code into smaller chunks and loading them only when they are
needed. */

const Logo = lazy(() => import("./component/logo/Logo"));
const Loading = lazy(() => import("./component/loading/Loading"));
const UserProfile = lazy(() => import("./component/user-profile/UserProfile"));
const CommonNavBar = lazy(() => import("./component/common-navBar/CommonNavBar"));
const Login = lazy(() => import("./pages/login/Login"));
const SignUp = lazy(() => import("./pages/sign-up/SignUp"));
const Assessment = lazy(() => import("./pages/assessment/Assessment"));
const CustomerOverView = lazy(() => import("./pages/customer/over-view/CustomerOverView"));
const PersonalTax = lazy(() => import("./pages/customer/personal-tax/PersonalTax"));
const Settings = lazy(() => import("./pages/customer/settings/Settings"));
const InsightsGuides = lazy(() => import("./pages/customer/insights-and-guides/InsightsGuides"));
const LimitedCompany = lazy(() => import("./pages/customer/limited-company/LimitedCompany"));
const AdminOverView = lazy(() => import("./pages/admin/admin-over-view/AdminOverView"));
const AdminBussiness = lazy(() => import("./pages/admin/admin-businesses/AdminBussiness"));
const AdminAccountants = lazy(() => import("./pages/admin/admin-accountants/AdminAccountants"));
const AdminCustomers = lazy(() => import("./pages/admin/admin-customers/AdminCustomers"));
const AdminSettings = lazy(() => import("./pages/admin/admin-settings/AdminSettings"));
const AdminAssessmentStatus = lazy(
  () => import("./pages/admin/admin-assessment-status/AdminAssessmentStatus"),
);
const AdminProfile = lazy(() => import("./pages/admin/admin-profile/AdminProfile"));
const AdminParterships = lazy(
  () => import("./pages/admin/admin-client/admin-parterships/AdminParterships"),
);
const AdminCompany = lazy(() => import("./pages/admin/admin-client/admin-company/AdminCompany"));
const AdminIndividuals = lazy(
  () => import("./pages/admin/admin-client/admin-individuals/AdminIndividuals"),
);
const AdminInsightsAndGuides = lazy(
  () => import("./pages/admin/admin-insights-and-guides/AdminInsightsAndGuides"),
);
const AccountantOverview = lazy(
  () => import("./pages/accountant/accountant-overview/AccountantOverview"),
);
const PartnershipTax = lazy(() => import("./pages/customer/partnership-tax/PartnershipTax"));
const OtpVerification = lazy(() => import("./pages/verify-otp/VerifyOtp"));
const ForgotPassword = lazy(() => import("./pages/forgot-password/ForgotPass"));
const LandingPage = lazy(() => import("./pages/landing-page/Landing"));
const IntermediateTaxPage = lazy(
  () => import("./pages/intermediate-tax-page/IntermediateLandingPage"),
);
const AdminLoginPage = lazy(() => import("./pages/admin/admin-login/AdminLogin"));
const TermsPage = lazy(() => import("./pages/login/TermsAndPolicy"));
const AddBusinessPage = lazy(() => import("./pages/admin/admin-businesses/AddAdminBusiness"));
const AddAccountantsPage = lazy(
  () => import("./pages/admin/admin-accountants/AddAdminAccountants"),
);
const AccountantLoginPage = lazy(
  () => import("./pages/accountant/accountant-login/AccountantLogin"),
);
const ZoomMeeting = lazy(() => import("./pages/customer/zoom/ZoomMeeting"));
const AccountantPasswordResetPage = lazy(
  () => import("./pages/accountant/accountant-password-reset/AccountantPasswordReset"),
);

const HmrcDatesPage = lazy(() => import("./pages/hmrc-dates/hmrcDatesPage"));
const AccountantReviewPage = lazy(
  () => import("./pages/accountant/accountant-reviews/AccountantReviews"),
);
const AccountantServiceRequestsPage = lazy(
  () => import("./pages/accountant/accountant-service-requests/AccountantServiceRequest"),
);
const ServiceManagerOverviewPage = lazy(
  () => import("./pages/service-manager/service-manager-overview/ServiceManagerOverview"),
);
const ServiceManagerLoginPage = lazy(
  () => import("./pages/service-manager/service-manager-login/ServiceManagerLogin"),
);
const AvailableAccountantsPage = lazy(
  () => import("./pages/service-manager/available-accountants/AvailableAccountants"),
);
const ServiceManagerServiceRequestsPage = lazy(
  () => import("./pages/service-manager/service-requests/ServiceManagerServiceRequests"),
);
const ServiceManagerRecentActivityPage = lazy(
  () => import("./pages/service-manager/service-manager-recent-activity/RecentActivities"),
);
const AddBusinessLandingPage = lazy(
  () => import("./pages/add-business-landing/AddBusinessLanding"),
);
const AccountantSettingsPage = lazy(
  () => import("./pages/accountant/accountant-settings/AccountantSettings"),
);
const AccountantCustomerProfile = lazy(() => import("./pages/accountant/AccountantComingSoon"));
const ServiceManagerProfile = lazy(() => import("./pages/service-manager/ComingSoon"));
const PaymentCheckoutPage = lazy(() => import("./pages/payments/StripeCheckout"));
const AllPastTaxFilingsPage = lazy(() => import("./pages/customer/over-view/AllPastTaxFiling"));

const App = () => {
  const theme = useMantineTheme();

  // This function checks for the public routes which are accessible based on the user role

  return (
    <Box className="App" bg={theme.white[0]}>
      <Router>
        <ErrorBoundary>
          <Suspense fallback={<Loading />}>
            <Routes>
              {/** Public Routes */}
              <Route path="/" element={<Navigate to={ROUTES.LANDING} replace />} />

              <Route element={<ProtectedRoleRoute allowedRoles={[]} />}>
                <Route path={ROUTES.LANDING} element={<LandingPage />} />
                <Route path={ROUTES.SIGNUP} element={<SignUp />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={ROUTES.ADD_BUSINESS_LANDING} element={<AddBusinessLandingPage />} />
              </Route>

              <Route path={ROUTES.PRIVACY_POLICY} element={<TermsPage />} />
              <Route path="*" element={<Forbidden />} />

              <Route
                element={
                  <ProtectedRoleRoute
                    allowedRoles={[USER_TYPE.CUSTOMER, USER_TYPE.ACCOUNTANT, USER_TYPE.BOOKKEEPER]}
                  />
                }>
                <Route path={ROUTES.ASSESSMENT} element={<Assessment />} />
              </Route>

              {/** Protected Customer Routes */}
              <Route element={<ProtectedRoleRoute allowedRoles={[USER_TYPE.CUSTOMER]} />}>
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.VERIFY_OTP} element={<OtpVerification />} />
                <Route path={ROUTES.PAYMENT_PAGE} element={<PaymentCheckoutPage />} />
                <Route path={ROUTES.ZOOM_MEETING} element={<ZoomMeeting />} />
                <Route
                  element={
                    <CommonNavBar
                      userType={USER_TYPE.CUSTOMER}
                      navbarLinkData={customerNavbarLinkData}
                      // navBarHeader={<Logo />}
                      navBarFooter={
                        <UserProfile route={ROUTES.CUSTOMER_PROFILE} role={USER_TYPE.CUSTOMER} />
                      }
                    />
                  }>
                  <Route path={ROUTES.INTERMEDIATE_TAX_PAGE} element={<IntermediateTaxPage />} />
                  <Route path={ROUTES.OVERVIEW} element={<CustomerOverView />} />
                  <Route path={ROUTES.CUSTOMER_PROFILE} element={<CustomerProfile />} />
                  <Route path={ROUTES.PERSONAL_TAX} element={<PersonalTax />} />
                  <Route path={ROUTES.PARTNERSHIP_TAX} element={<PartnershipTax />} />
                  <Route path={ROUTES.SETTINGS} element={<Settings />} />
                  <Route path={ROUTES.INSIGHTS_GUIDES} element={<InsightsGuides />} />
                  <Route path={ROUTES.LIMITED_COMPANY} element={<LimitedCompany />} />
                  <Route path={ROUTES.HMRC_DATES} element={<HmrcDatesPage />} />
                  <Route path={ROUTES.ALL_PAST_TAX} element={<AllPastTaxFilingsPage />} />
                </Route>
              </Route>
              {/* Admin Routes */}
              <Route element={<ProtectedRoleRoute allowedRoles={[USER_TYPE.ADMIN]} />}>
                <Route path={ROUTES.ADMIN_LOGIN} element={<AdminLoginPage />} />

                <Route
                  element={
                    <CommonNavBar
                      userType={USER_TYPE.ADMIN}
                      navbarLinkData={admiNavbarLinkData}
                      navBarHeader={<Logo />}
                      navBarFooter={
                        <UserProfile route={ROUTES.ADMIN_PROFILE} role={USER_TYPE.ADMIN} />
                      }
                    />
                  }>
                  <Route path={ROUTES.ADMIN_OVERVIEW} element={<AdminOverView />} />
                  <Route path={ROUTES.ADMIN_BUSINESSES} element={<AdminBussiness />} />
                  <Route path={ROUTES.ADMIN_ACCOUNTANTS} element={<AdminAccountants />} />
                  <Route path={ROUTES.ADMIN_CUSTOMERS} element={<AdminCustomers />} />
                  <Route path={ROUTES.ADMIN_SETTINGS} element={<AdminSettings />} />
                  <Route
                    path={ROUTES.ADMIN_ASSESSMENT_STATUS}
                    element={<AdminAssessmentStatus />}
                  />
                  <Route path={ROUTES.ADMIN_PROFILE} element={<AdminProfile />} />
                  <Route path={ROUTES.ADMIN_INDIVIDUALS} element={<AdminIndividuals />} />
                  <Route path={ROUTES.ADMIN_COMPANY} element={<AdminCompany />} />
                  <Route path={ROUTES.ADMIN_PARTERSHIPS} element={<AdminParterships />} />
                  <Route path={ROUTES.ADMIN_ADD_BUSINESS} element={<AddBusinessPage />} />
                  <Route path={ROUTES.ADMIN_ADD_ACCOUNTANTS} element={<AddAccountantsPage />} />
                  <Route path={ROUTES.ADMIN_HMRC_DATE_PAGE} element={<HmrcDatesPage />} />
                  <Route
                    path={ROUTES.ASMIN_INSIGHTS_AND_DUIDES}
                    element={<AdminInsightsAndGuides />}
                  />
                </Route>
              </Route>
              {/* Accountant Routes */}
              <Route
                element={
                  <ProtectedRoleRoute allowedRoles={[USER_TYPE.ACCOUNTANT, USER_TYPE.BOOKKEEPER]} />
                }>
                <Route path={ROUTES.ACCOUNTANT_LOGIN} element={<AccountantLoginPage />} />
                <Route path={ROUTES.ACCOUNTANT_FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route
                  path={ROUTES.ACCOUNTANT_PASSWORD_RESET}
                  element={<AccountantPasswordResetPage />}
                />
                <Route
                  element={
                    <CommonNavBar
                      userType={USER_TYPE.ACCOUNTANT}
                      navbarLinkData={accountantNavbarLinkData}
                      navBarHeader={<Logo />}
                      navBarFooter={
                        <UserProfile
                          route={ROUTES.ACCOUNTANT_CUSTOMER_PROFILE}
                          role={USER_TYPE.ACCOUNTANT}
                        />
                      }
                    />
                  }>
                  <Route path={ROUTES.ACCOUNTANT_CUSTOMER_PROFILE} element={<CustomerProfile />} />
                  <Route path={ROUTES.ACCOUNTANT_OVERVIEW} element={<AccountantOverview />} />
                  <Route path={ROUTES.ACCOUNTANT_SETTINGS} element={<AccountantSettingsPage />} />
                  <Route path={ROUTES.ACCOUNTANT_TAX_FILLING} element={<AdminOverView />} />
                  <Route path={ROUTES.ACCOUNTANT_CHAT_HISTORY} element={<AdminOverView />} />
                  <Route path={ROUTES.ACCOUNTANTA_INSIGHTS_GUIDES} element={<AdminOverView />} />
                  <Route path={ROUTES.ACCOUNTANTA_SETTINGS} element={<AdminOverView />} />
                  <Route path={ROUTES.ACCOUNTANT_HMRC_DATE_PAGE} element={<HmrcDatesPage />} />
                  <Route path={ROUTES.ACCOUNTANT_REVIEWS} element={<AccountantReviewPage />} />
                  <Route
                    path={ROUTES.ACCOUNTANT_SERVICE_REQUESTS}
                    element={<AccountantServiceRequestsPage />}
                  />
                </Route>
              </Route>
              {/* service manager routes */}
              <Route element={<ProtectedRoleRoute allowedRoles={[USER_TYPE.SERVICE_MANAGER]} />}>
                <Route path={ROUTES.SERVICE_MANAGER_LOGIN} element={<ServiceManagerLoginPage />} />

                <Route
                  element={
                    <CommonNavBar
                      userType={USER_TYPE.SERVICE_MANAGER}
                      navbarLinkData={serviceManagerNavbarLinkData}
                      navBarHeader={<Logo />}
                      navBarFooter={
                        <UserProfile
                          route={ROUTES.SERVICE_MANAGER_CUSTOMER_PROFILE}
                          role={USER_TYPE.SERVICE_MANAGER}
                        />
                      }
                    />
                  }>
                  <Route
                    path={ROUTES.SERVICE_MANAGER_CUSTOMER_PROFILE}
                    element={<CustomerProfile />}
                  />
                  <Route
                    path={ROUTES.SERVICE_MANAGER_OVERVIEW}
                    element={<ServiceManagerOverviewPage />}
                  />
                  <Route
                    path={ROUTES.AVAILABLE_ACCOUNTANTS}
                    element={<AvailableAccountantsPage />}
                  />
                  <Route
                    path={ROUTES.SERVICE_REQUESTS}
                    element={<ServiceManagerServiceRequestsPage />}
                  />
                  <Route
                    path={ROUTES.RECENT_ACTIVITIES}
                    element={<ServiceManagerRecentActivityPage />}
                  />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </Box>
  );
};

export default App;
