import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./slice/assessmentSlice";
import { taxdApi } from "./api";
import { loginSlice } from "./slice/loginSlice";
import subFormIdReducer from "./slice/subFormIdSlice";

// Persist configuration for loginSlice
const loginPersistConfig = {
  key: "login",
  storage,
  whitelist: ["userLoginInfo"], // Only persist the userLoginInfo field
};

// Apply the persistReducer to loginSlice
const persistedLoginReducer = persistReducer(loginPersistConfig, loginSlice.reducer);

export const store = configureStore({
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for redux-persist
    }).concat(taxdApi.middleware),
  reducer: {
    [taxdApi.reducerPath]: taxdApi.reducer,
    userReducer,
    loginSlice: persistedLoginReducer, // Use the persisted reducer here
    subFormIdReducer,
  },
});

// Create a persister for the store
export const persister = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
