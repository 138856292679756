import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Group,
  Text,
  Button,
  Avatar,
  Badge,
  Grid,
  Stack,
  rem,
  useMantineTheme,
  FileButton,
  Box,
  LoadingOverlay,
  Modal,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import ProfileUpdateModal from "./ProfileUpdateModal";
import PasswordUpdateModal from "./CustomerPasswordUpdateModal";
import AddressUpdateModal from "./AddressUpdateModal";
import { CONSTANT, MODAL_MSGS, ROUTES } from "../../../utility/constant";
import ComingSoon from "../../../component/coming-soon/ComingSoon";
import {
  useUserProfileSettingsDetailsQuery,
  useUpdateProfileDetailsMutation,
} from "../../../store/api";

const CustomerProfile = () => {
  const theme = useMantineTheme();
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [removeProfilePic, setRemoveProfilePic] = useState<boolean>(false);
  const [profileModalOpened, { open: openProfileModal, close: closeProfileModal }] =
    useDisclosure(false);
  const [passwordModalOpened, { open: openPasswordModal, close: closePasswordModal }] =
    useDisclosure(false);
  const [addressModalOpened, { open: openAddressModal, close: closeAddressModal }] =
    useDisclosure(false);
  const [removePicModalOpened, { open: openRemovePicModal, close: closeRemovePicModal }] =
    useDisclosure(false);

  const [updateUserProfile] = useUpdateProfileDetailsMutation();
  const { data: userProfileData, isLoading: visible } = useUserProfileSettingsDetailsQuery();
  const profileDetails = userProfileData?.data || {};

  const handleFileChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const result = reader.result as string;
        // setSelectedFile(result);

        // Automatically update the profile picture after selecting a file by calling the API
        try {
          const patchBody = {
            userProfile: {
              profilePic: result,
            },
          };
          await updateUserProfile({
            patch: patchBody,
          }).unwrap();

          notifications.show({
            title: CONSTANT.SUCCESS,
            message: "Profile picture updated successfully.",
            color: "green",
            position: "top-right",
          });
          setSelectedFile(result);
        } catch (err) {
          notifications.show({
            title: CONSTANT.ERROR,
            message: err?.data?.message || "Error updating profile picture.",
            color: "red",
            position: "top-right",
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveFile = async () => {
    setSelectedFile(null);
    closeRemovePicModal();
    // Automatically remove the profile picture after clicking remove button by calling the API
    try {
      const patchBody = {
        userProfile: {
          isProfileRemoved: true,
        },
      };
      await updateUserProfile({
        patch: patchBody,
      }).unwrap();
      setRemoveProfilePic(true);
      notifications.show({
        title: CONSTANT.SUCCESS,
        message: "Profile picture removed successfully.",
        color: "green",
        position: "top-right",
      });
    } catch (err) {
      notifications.show({
        title: CONSTANT.ERROR,
        message: err?.data?.message || "Error in removing profile picture.",
        color: "red",
        position: "top-right",
      });
    }
  };

  return (
    <Container size="md" py="lg">
      <Modal
        opened={removePicModalOpened}
        onClose={closeRemovePicModal}
        size="auto"
        withCloseButton={false}
        centered>
        <Text ff="Poppins">{MODAL_MSGS.REMOVE_PROFILE_PICTURE}</Text>
        <Group mt="xl" grow>
          <Button
            variant="outline"
            color={theme.colors.deepBlue[4]}
            onClick={handleRemoveFile}
            c={theme.colors.deepBlue[4]}>
            {MODAL_MSGS?.MODAL_YES}
          </Button>
          <Button color={theme.colors.deepBlue[4]} onClick={closeRemovePicModal}>
            {MODAL_MSGS?.MODAL_NO}
          </Button>
        </Group>
      </Modal>
      {/* Profile Section */}
      <Paper withBorder p="lg" radius="md" mb="md">
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: theme.colors.deepBlue[9], type: "bars" }}
        />
        <Group justify="space-between">
          <Stack gap={1}>
            <Text fw={700} size="lg">
              Profile
            </Text>
            <Text c="dimmed" fw={700} size="sm">
              {CONSTANT.PROVIDE_ALL_THE_DETAILS_REQUIRED}
            </Text>
          </Stack>
          <Group gap={10}>
            <Text fw={500} c="dimmed">
              {CONSTANT.STATUS}
            </Text>
            <Badge color="green" variant="dot" radius="sm">
              {CONSTANT.ACTIVE}
            </Badge>
          </Group>
        </Group>

        <Group justify="space-between" mt="lg">
          <Group>
            {profileDetails.profilePic ? (
              <Avatar
                src={profileDetails.profilePic}
                size={rem(100)}
                radius="50%"
                alt="Profile Picture"
                styles={{
                  root: {
                    border: "2px solid #ccc",
                    // padding: "5px",
                  },
                }}
              />
            ) : (
              <>
                {" "}
                <Avatar
                  src={selectedFile}
                  size={rem(100)}
                  radius="50%"
                  alt="Profile Picture"
                  styles={{
                    root: {
                      border: "2px solid #ccc",
                      // padding: "5px",
                    },
                  }}
                />
              </>
            )}

            <Stack gap={5}>
              <Text>Profile Picture</Text>
              <Text size="xs" c="dimmed">
                {CONSTANT.PROFILE_MAX_SIZE}
              </Text>
            </Stack>
          </Group>
          <Group>
            <Button
              variant="outline"
              size="xs"
              color={theme.colors.deepBlue[4]}
              onClick={openRemovePicModal}>
              {CONSTANT.REMOVE_PICTURE}
            </Button>
            <FileButton onChange={handleFileChange} accept="image/png,image/jpeg">
              {(props) => (
                <Button {...props} size="xs" color={theme.colors.deepBlue[4]}>
                  {CONSTANT.UPLOAD_NEW_PICTURE}
                </Button>
              )}
            </FileButton>
          </Group>
        </Group>
      </Paper>

      {/* Account Section */}
      <Paper withBorder p="lg" radius="md" mb="md">
        <Group justify="space-between">
          <Stack gap={1}>
            <Text fw={700} size="lg">
              {CONSTANT.ACCOUNT}
            </Text>
            <Text c="dimmed" fw={700} size="sm">
              {CONSTANT.PROVIDE_ALL_THE_DETAILS_REQUIRED}
            </Text>
          </Stack>
          <Button
            variant="outline"
            size="xs"
            color={theme.colors.deepBlue[4]}
            style={{ marginTop: "10px", float: "right" }}
            onClick={openProfileModal}>
            {CONSTANT.CHANGE}
          </Button>
        </Group>

        <Grid mt="lg">
          <Grid.Col span={6}>
            <Text size="sm" fw={500}>
              {CONSTANT.FIRSTNAME}
            </Text>
            <Text size="sm" c="dimmed">
              {profileDetails.firstName}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="sm" fw={500}>
              {CONSTANT.LASTNAME}
            </Text>
            <Text size="sm" c="dimmed">
              {profileDetails.lastName}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="sm" fw={500}>
              {CONSTANT.PHONENUMBER}
            </Text>
            <Text size="sm" c="dimmed">
              {profileDetails?.phoneNumber}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="sm" fw={500}>
              {CONSTANT.EMAILADDRESS}
            </Text>
            <Text size="sm" c="dimmed">
              {profileDetails.email}
            </Text>
          </Grid.Col>
        </Grid>
      </Paper>

      {/* Change Password Section */}
      <Paper withBorder p="lg" radius="md" mb="md">
        <Group justify="space-between">
          <Stack gap={1}>
            <Text fw={700} size="lg">
              {CONSTANT.CHANGE_PASSWORD}
            </Text>
            <Text c="dimmed" fw={700} size="sm">
              {CONSTANT.PASSWORD_DESCRIPTION}
            </Text>
          </Stack>
          <Button
            variant="outline"
            size="xs"
            color={theme.colors.deepBlue[4]}
            onClick={openPasswordModal}>
            {CONSTANT.CHANGE}
          </Button>
        </Group>

        <Group justify="apart" mt="lg">
          <Text>*********</Text>
          {/** Commented for now as from backend we are not getting the last updated date */}
          {/* <Text size="xs" color="dimmed">
            Last Updated On 24 Nov 2022
          </Text> */}
        </Group>
      </Paper>

      {/* Address Section */}
      <Paper withBorder p="lg" radius="md" mb="md">
        <Group justify="space-between">
          <Stack gap={1}>
            <Text fw={700} size="lg">
              {CONSTANT.ADDRESS}
            </Text>
            <Text c="dimmed" fw={700} size="sm">
              {CONSTANT.PROVIDE_ADDRESS}
            </Text>
          </Stack>
          <Button
            variant="outline"
            size="xs"
            color={theme.colors.deepBlue[4]}
            style={{ marginTop: "10px", float: "right" }}
            onClick={openAddressModal}>
            {profileDetails?.address ? "Change" : "Add"}
          </Button>
        </Group>

        <Grid mt="lg">
          <Grid.Col span={6}>
            <Text size="sm" fw={500}>
              {CONSTANT.COUNTRY}
            </Text>
            <Text size="sm" c="dimmed">
              {profileDetails?.address?.country}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="sm" fw={500}>
              {CONSTANT.CITY_STATE}
            </Text>
            <Text size="sm" c="dimmed">
              {profileDetails?.address?.city}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="sm" fw={500}>
              {CONSTANT.HOUSE_STREET_NO}
            </Text>
            <Text size="sm" c="dimmed">
              {profileDetails?.address?.street}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="sm" fw={500}>
              {CONSTANT.POSTAL_CODE}
            </Text>
            <Text size="sm" c="dimmed">
              {profileDetails?.address?.zipCode}
            </Text>
          </Grid.Col>
        </Grid>
      </Paper>
      <ProfileUpdateModal
        opened={profileModalOpened}
        close={closeProfileModal}
        profileDetails={profileDetails}
      />
      <PasswordUpdateModal opened={passwordModalOpened} close={closePasswordModal} />
      <AddressUpdateModal
        opened={addressModalOpened}
        close={closeAddressModal}
        profileDetails={profileDetails}
      />
    </Container>
  );
  // return <ComingSoon />;
};

export default CustomerProfile;
