import {
  Box,
  SimpleGrid,
  PasswordInput,
  Button,
  Stack,
  Modal,
  rem,
  useMantineTheme,
  Group,
} from "@mantine/core";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  CONSTANT,
  ERROR_MESSAGE,
  ROUTES,
  REGEX,
  StorageConstants,
} from "../../../utility/constant";
import { useUpdateCurrentPasswordMutation } from "../../../store/api";

const PasswordUpdateModal = ({ close, opened }) => {
  const theme = useMantineTheme();
  const [updatePassword, { isLoading: visible }] = useUpdateCurrentPasswordMutation();

  const form = useForm({
    mode: "controlled",
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validate: {
      currentPassword: isNotEmpty("Current password cannot be empty"),
      newPassword: (value, values) => {
        if (value === values.currentPassword) {
          return ERROR_MESSAGE.CURRENT_NEW_PASSWORD_MATCH;
        }
        if (value.length === 0) {
          return ERROR_MESSAGE.INVALID_PASSWORD;
        }
        if (value.length < CONSTANT.PASSWORDMINLEN) {
          return `Password should include at least ${CONSTANT.PASSWORDMINLEN} characters`;
        }
        if (!REGEX.UPPERCASEREGEX.test(value)) {
          return ERROR_MESSAGE.INVALID_UPPERCASE;
        }
        if (!REGEX.LOWERCASEREGEX.test(value)) {
          return ERROR_MESSAGE.INVALID_LOWERCASE;
        }
        if (!REGEX.DIGITREGEX.test(value)) {
          return ERROR_MESSAGE.INVALID_DIGIT;
        }
        if (!REGEX.SPECIALCHARREGEX.test(value)) {
          return ERROR_MESSAGE.INVALID_SPECIALCHAR;
        }
        return null;
      },
      confirmNewPassword: (value, values) =>
        value !== values.newPassword ? ERROR_MESSAGE.MISMATCHED_PASSWORD : null,
    },
    validateInputOnBlur: true,
  });

  const handlePasswordUpdate = async () => {
    const { currentPassword, newPassword } = form.values;
    await updatePassword({
      patch: {
        userId: localStorage.getItem(StorageConstants.USER_ID),
        currentPassword,
        newPassword,
      },
    })
      .unwrap()
      .then((res) => {
        notifications.show({
          title: CONSTANT.SUCCESS,
          message: res?.message || CONSTANT.SUCCESS,
          color: "Green",
          position: "top-right",
        });
        form.reset();
        close();
      })
      .catch((err) => {
        notifications.show({
          title: CONSTANT.ERROR,
          message: err?.data?.message || CONSTANT.SWW,
          color: "red",
          position: "top-right",
        });
      });
  };

  return (
    <Modal opened={opened} onClose={close} title="Update Password" size="lg" radius={20} centered>
      {/* Modal content */}
      <Box mb="md">
        <form
          style={{ width: "100%" }}
          onSubmit={form.onSubmit(() => {
            handlePasswordUpdate();
          })}>
          <Stack>
            <PasswordInput
              withAsterisk
              label="Current Password"
              placeholder="Current password"
              radius="md"
              size="md"
              c="dimmed"
              value={form.values.currentPassword}
              onChange={(event) => {
                form.setFieldValue("currentPassword", event.target.value.replace(/\s/g, ""));
              }}
              error={form.errors.currentPassword ?? ""}
            />
            <PasswordInput
              withAsterisk
              label="New Password"
              placeholder="New password"
              radius="md"
              size="md"
              c="dimmed"
              value={form.values.newPassword}
              onChange={(event) => {
                form.setFieldValue("newPassword", event.target.value.replace(/\s/g, ""));
              }}
              error={form.errors.newPassword ?? ""}
            />
            <PasswordInput
              withAsterisk
              label="Confirm New Password"
              placeholder="Confirm New password"
              radius="md"
              size="md"
              c="dimmed"
              value={form.values.confirmNewPassword}
              onChange={(event) => {
                form.setFieldValue("confirmNewPassword", event.target.value.replace(/\s/g, ""));
              }}
              error={form.errors.confirmNewPassword ?? ""}
            />
            <Group justify="flex-end">
              <Button
                type="submit"
                radius="md"
                w="100%"
                mt="sm"
                maw="40%"
                bg={theme.colors.deepBlue[9]}
                c="white"
                loading={visible}
                h={rem(50)}>
                update
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default PasswordUpdateModal;
