import {
  Box,
  SimpleGrid,
  TextInput,
  Button,
  Stack,
  Modal,
  rem,
  useMantineTheme,
  Group,
  Select,
  NumberInput,
} from "@mantine/core";
import { useState, useEffect } from "react";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { CONSTANT, ERROR_MESSAGE, MODAL_MSGS, ROUTES } from "../../../utility/constant";
import { useCountryListAPIQuery, useUpdateProfileDetailsMutation } from "../../../store/api";

const ProfileUpdateModal = ({ close, opened, profileDetails }) => {
  const theme = useMantineTheme();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(profileDetails?.phoneNumber || "");
  const [updateUserProfile, { isLoading: visible }] = useUpdateProfileDetailsMutation();
  const { data: countryData } = useCountryListAPIQuery({ countryList: [] });
  const countryList = countryData?.data;

  const form = useForm({
    mode: "controlled",
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phoneNumber: "",
    },
    validate: {
      firstName: isNotEmpty(ERROR_MESSAGE.INVALID_FIRST_NAME),
      lastName: isNotEmpty(ERROR_MESSAGE.INVALID_LAST_NAME),
      phoneNumber: (value) => {
        if (!selectedCountry) {
          return ERROR_MESSAGE.PLEASE_SELECT_COUNTRY_CODE; // Message for missing country code
        }
        if (!value) {
          return ERROR_MESSAGE.INVALID_PHONE_NUMBER; // Message for empty input
        }
        if (value.length < selectedCountry?.maxPhoneLength) {
          return ERROR_MESSAGE.PLEASE_ENTER_VALID_PHONE_NUMBER; // Message for less than 10 digits
        }
        return null; // No error
      },
    },
    validateInputOnBlur: true,
  });

  const handleProfileUpdate = async () => {
    const { firstName, lastName } = form.values;
    await updateUserProfile({
      patch: {
        userProfile: {
          firstName,
          lastName,
          countryCode: selectedCountry?.phoneCode,
          phoneNumber,
        },
      },
    })
      .unwrap()
      .then((res) => {
        notifications.show({
          title: CONSTANT.SUCCESS,
          message: res?.message || CONSTANT.SUCCESS,
          color: "Green",
          position: "top-right",
        });
        form.reset();
        close();
      })
      .catch((err) => {
        notifications.show({
          title: CONSTANT.ERROR,
          message: err?.data?.message || CONSTANT.SWW,
          color: "red",
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    const formattedCountries = countryList?.map((country) => ({
      value: country?.countryCode,
      label: `${country?.flagIcon} (${country?.phoneCode}) ${country?.countryName}`,
      phoneCode: country?.phoneCode,
      maxPhoneLength: country?.maxPhoneLength,
      countryName: country?.countryName,
    }));
    setCountries(formattedCountries);
  }, [countryData, profileDetails]);

  const handleCountryChange = (value) => {
    const selected = countries?.find((country) => country.value === value);
    setSelectedCountry(selected);
    // setPhoneNumber(""); // Reset phone number when a new country is selected
    // form.setFieldValue("phoneNumber", ""); // Ensure form state is updated
  };

  const handlePhoneChange = (event) => {
    const value = event;
    if (selectedCountry) {
      setPhoneNumber(value);
      form.setFieldValue("phoneNumber", value); // Update form state
    }
  };

  useEffect(() => {
    if (profileDetails) {
      const clonedAddress = JSON.parse(JSON.stringify(profileDetails));
      form.setInitialValues(clonedAddress);
      form.setValues(clonedAddress);

      const selected = countries?.find(
        (country) => country?.phoneCode === profileDetails?.countryCode,
      );
      setSelectedCountry(selected);
      setPhoneNumber(profileDetails?.phoneNumber || "");
    }
  }, [profileDetails, countries]);
  return (
    <Modal
      opened={opened}
      onClose={close}
      title={MODAL_MSGS.UPDATE_PROFILE_TITLE}
      size="lg"
      radius={20}
      centered>
      <Box mb="md">
        <form
          style={{ width: "100%" }}
          onSubmit={form.onSubmit(() => {
            handleProfileUpdate();
          })}>
          <Stack>
            <SimpleGrid
              cols={{ base: 1, sm: 2, lg: 2 }}
              spacing={{ base: 10 }}
              verticalSpacing={{ base: "sm" }}>
              <TextInput
                label={CONSTANT.FIRSTNAME}
                placeholder={CONSTANT.FIRSTNAME}
                withAsterisk
                size="md"
                radius="md"
                mt="md"
                c="dimmed"
                defaultValue={profileDetails?.firstName}
                // value={form.values.firstName}
                onChange={(event) => form.setFieldValue("firstName", event.target.value.trim())}
                error={form.errors.firstName ?? ""}
              />
              <TextInput
                label={CONSTANT.LASTNAME}
                placeholder={CONSTANT.LASTNAME}
                withAsterisk
                radius="md"
                size="md"
                mt="md"
                c="dimmed"
                defaultValue={profileDetails?.lastName}
                // value={form.values.lastName}
                onChange={(event) => form.setFieldValue("lastName", event.target.value.trim())}
                error={form.errors.lastName ?? ""}
              />
            </SimpleGrid>
            <Stack>
              <Group justify="space-between">
                <Select
                  label={CONSTANT.SELECT_COUNTRY}
                  placeholder={CONSTANT.CHOOSE_COUNTRY}
                  data={countries}
                  value={selectedCountry?.value || ""}
                  onChange={handleCountryChange}
                  searchable
                  nothingFoundMessage="No options"
                  radius="md"
                  size="md"
                  mt="sm"
                  c="dimmed"
                  w="35%"
                  allowDeselect={false}
                />
                <NumberInput
                  hideControls
                  withAsterisk
                  label={CONSTANT.PHONENUMBER}
                  placeholder={CONSTANT.ENTER_PHONE_NUMBER}
                  radius="md"
                  size="md"
                  mt="sm"
                  c="dimmed"
                  w="60%"
                  inputMode="numeric"
                  maxLength={selectedCountry?.maxPhoneLength}
                  defaultValue={phoneNumber}
                  // value={phoneNumber}
                  onChange={handlePhoneChange}
                  error={form.errors.phoneNumber ?? ""}
                  disabled={!selectedCountry}
                />
              </Group>
              <TextInput
                withAsterisk
                label={CONSTANT.EMAIL}
                radius="md"
                size="md"
                mt="sm"
                c="dimmed"
                disabled
                defaultValue={profileDetails?.email}
                // value={form.values.email}
              />
            </Stack>
            <Group justify="flex-end">
              <Button
                type="submit"
                radius="md"
                w="100%"
                mt="sm"
                maw="40%"
                bg={theme.colors.deepBlue[9]}
                loading={visible}
                c="white"
                h={rem(50)}>
                {CONSTANT.UPDATE}
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default ProfileUpdateModal;
